import React, { useEffect, useState } from 'react'

import { Form } from 'react-bootstrap'

import { toast } from 'react-toastify';
import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { deleteapi, getwithheader, postwithheader } from '../Api/Apis';
import TitleComp from '../component/TitleComp';

function Addpolicy() {
    let token = localStorage.getItem("paneltoken");

    const [data, setdata] = useState([])
    const [title, settitle] = useState("");
    const [description, setdescription] = useState("");
    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission
        const requestData = {
            title: title,
            description: description,
        };

        const res = await postwithheader('policy', requestData, token);
        if (res.error == "0") {
            toast.success(res.message)
            handleget();
            settitle(""); // Reset form fields
            setdescription("");

        } else {
            toast.error("Data not Submit")
        }
    };
    const handleget = async () => {
        const res = await getwithheader('policy', token)
        console.log(res.data)
        setdata(res.data)


    }
    useEffect(() => {
        handleget();
    }, [])
    const handleedit = (e, item) => {
        e.preventDefault()
        settitle(item.title)
        setdescription(item.description)

    }
    const handledelete = async (e, item) => {
        e.preventDefault()
        const res = await deleteapi(`policy/${item.url}`, token)
        if (res.error == "0") {
            toast.success(res.message)
            handleget()
        } else {
            toast.error("Data not Submitted")
        }
    }
    const getRowId = (row) => row._id

    const columns = [
        {
            field: "title",
            headerName: "Title",
            flex: 1,

        },

        {
            field: "description",
            headerName: "Description",
            flex: 1,

        },

        {
            field: "action",
            headerName: "Action",
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        <div className='d-flex gap-2 mt-1'>
                            <button className='accepted btn bg-dark text-white' onClick={(e) => handleedit(e, params.row)}><i class="fa-solid fa-pen-to-square"></i> </button>
                            <button className='accepted btn bg-dark text-white' onClick={(e) => handledelete(e, params.row)} ><i class="fa-solid fa-trash"></i></button>
                        </div>
                    </>
                )
            }

        }
    ]
    return (
        <>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="text-center">
                                <TitleComp title="Add Policy" />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <Form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-4">
                                        <label htmlFor="">Tilte</label>
                                        <input type="text"
                                            className='form-control'
                                            value={title}
                                            onChange={(e) => settitle(e.target.value)} />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="">Description</label>
                                        <input type="text" className='form-control'
                                            value={description}
                                            onChange={(e) => setdescription(e.target.value)} />
                                    </div>
                                    <div className="col-md-4 mt-4">
                                        <button type="submit" className="btn bg-primary text-white">
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        </div>

                    </div>
                </div>
            </section>
            <section className='mt-3'>
                <div className="container">
                    <div className="row">
                        <div className="text-center">
                            <h3>FAQ List</h3>
                        </div>
                        <div className="col-md-12">
                            <Box>
                                <DataGrid
                                    rows={data}
                                    columns={columns}
                                    getRowId={getRowId}
                                />

                            </Box>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )

};

export default Addpolicy
