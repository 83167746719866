
import React, { useState, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import './category.css';
import axios from "axios";
import { BASE_URL } from "../../URL";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Bars } from 'react-loader-spinner';
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { putwithheader } from "../../Api/Apis";

function Categories() {
  let token = localStorage.getItem("paneltoken")
  const [loading, setloading] = useState(false)
  const [name, setname] = useState("")
  const [description, setdescription] = useState("")
  const [file, setfile] = useState("")
  const [icon, seticon] = useState("")

  const [position, setposition] = useState("")

  const [Categories, setcategory] = useState([]);

  const [editid, seteditid] = useState("")

  const headers = {
    Authorization: `Bearer ${token}`

  }

  const handlefile = (e) => {
    e.preventDefault()
    let selectedfile = e.target.files[0]
    setfile(selectedfile)
  }
  const handlefileicon = (e) => {
    e.preventDefault()
    let selectedfile = e.target.files[0]
    seticon(selectedfile)
  }

  const handleget = () => {
    axios.get(`${BASE_URL}category`, { headers }).then((res) => {
      setcategory(res.data.data)
    })
  }

  useEffect(() => {
    handleget()
  }, []);


  const handlesubmit = async (e) => {
    e.preventDefault()

    setloading(true)

    let formdata = new FormData()
    formdata.append("name", name)
    formdata.append("description", description)
    if (file) {
      formdata.append("image", file)
    }
    if (icon) {
      formdata.append("icon", icon)
    }

    formdata.append("position", position)
    if (!editid) {

      axios.post(`${BASE_URL}category`, formdata, { headers }).then((response) => {
        // console.log(response);
        if (response.data.error == "0") {
          toast.success("data submitted successfully");
          handleget()
        }
      })
        .catch((error) => {
          console.error("Error:", error);
          toast.error("Error submitting data. Please try again later.");
        })
        .finally(() => {
          setloading(false);
        });
    } else {



      const res = await putwithheader(`category/${editid}`, formdata, token)
      if (res.error == "0") {
        toast.success(res.message)
        handleget()
        seteditid("")
        setloading(false)
      } else {
        toast.error("Data not Submit")
        setloading(false)
      }
    }
  };


  const handledelte = (e, id) => {
    setloading(true)
    e.preventDefault()
    axios.delete(`${BASE_URL}deletecategory/${id}`, { headers }).then((res) => {


      if (res.data.error == "0") {
        setloading(false)
        toast.success(res.data.message)
        handleget()
      }
    })
  }

  const getRowId = (row) => row._id

  const columns = [

    {
      field: "image",
      headerName: "Image",
      flex: 1,
      renderCell: (params) => {
        return (
          <img src={`${BASE_URL}${params.row.image}`} style={{ height: "50px", width: "50px", borderRadius: "50%" }} alt="" />
        )
      }
    },

    {
      field: "icon",
      headerName: "Icon",
      flex: 1,
      renderCell: (params) => {
        return (
          <img src={`${BASE_URL}${params.row.icon}`} style={{ height: "50px", width: "50px", borderRadius: "50%" }} alt="" />
        )
      }
    },

    {
      field: "name",
      headerName: "Name",
      flex: 1,

    },

    {
      field: "description",
      headerName: "description",
      flex: 1,

    },

    {
      field: "position",
      headerName: "Position",
      flex: 1,

    },

    {
      field: "delete",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <div className="d-flex gap-3 mt-1">
              <button className='btn bg-dark text-white' onClick={(e) => handleedit(e, params.row)}><i class="fa-solid fa-pen-to-square"></i></button>
              <button className='btn bg-dark text-white' onClick={(e) => handledelte(e, params.row._id)}><i class="fa-solid fa-trash"></i></button>
            </div>
          </>
        )
      }

    }
  ]

  const handleedit = (e, item) => {
    e.preventDefault()
    setname(item.name)
    setdescription(item.description)
    seteditid(item._id)
    setposition(item.position)
  }


  return (
    <>

      <ToastContainer />

      {loading && <div className="overlay"> (<Bars
        height="80"
        width="80"
        color="#4fa94d"
        ariaLabel="bars-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />) </div>}


      <section className="bg-body-tertiary">
        <div className="container-fluid">
          <h3 className="heading">CATEGORY</h3>
          <Form onSubmit={(e) => handlesubmit(e)}>
            <div className="row align-items-center mb-3">
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="">Title</label>
                  <input type="text" placeholder="Name" className="form-control" required value={name} onChange={(e) => setname(e.target.value)} />
                </div>

              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="">Positions</label>
                  <input type="text" placeholder="Positions" className="form-control" required value={position} onChange={(e) => setposition(e.target.value)} />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="">Image</label>
                  <input type="file" placeholder="" className="form-control" onChange={(e) => handlefile(e)} />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="">Icon</label>
                  <input type="file" placeholder="" className="form-control" onChange={(e) => handlefileicon(e)} />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label htmlFor="">Description</label>
                  <input type="text" placeholder="Description" className="form-control" required value={description} onChange={(e) => setdescription(e.target.value)} />
                </div>
              </div>
              <div className="col-md-4">
                <div className="my-3">
                  <Button className="btn" type="submit" >Submit</Button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </section>

      {/*  */}

      <section className="bg-body-tertiary" style={{ overflow: "hidden" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="">
                <Box>
                  <DataGrid
                    rows={Categories}
                    columns={columns}
                    getRowId={getRowId}
                  />

                </Box>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Categories;