import React from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Sidebar from './component/Sidebar';
import Dashboard from './Pages/Dashboard/Dashboard.jsx'


import Magazine from './Pages/Magazine.jsx';
import Event from './Pages/Event.jsx';
import CardList from './Pages/CardList.jsx';
import Login from './AuthPage/Login.jsx';
import Categories from './Pages/Categories/Categories.jsx';
import PrivateRoutes from './AuthPage/PrivateRoute.jsx';
import AddLocation from './Pages/AddLocation.jsx';
import Userlist from './Pages/Users/Userlist.jsx';
import Banner from './Pages/Banner/Banner.jsx';
import SubCategory from './Pages/Subcategory/SubCategory.jsx';
import SubCategoryList from './Pages/Subcategory/Subcategorylist.jsx';
import MembershipPage from './Pages/Membership/MembershipPage.jsx';
import AddBlogs from './Pages/Blogs/AddBlogs.jsx';
import EventRegistration from './Pages/EventPage/EventRegistration.jsx';
import AddEvent from './Pages/EventPage/AddEvent.jsx';
import EventList from './Pages/EventPage/EventList.jsx';
import AddFaq from './Pages/Faqs/AddFaq.jsx';
import Addpolicy from './Policy/Addpolicy.jsx';




const App = () => {
  return (
    <BrowserRouter>
      <Sidebar>
        <Routes >

          <Route element={<PrivateRoutes />}>
            {/* <Route path="/dashboard" element={<Dashboard />} /> */}
            <Route path="/categories" element={<Categories />} />
            <Route path="/magazine" element={<Magazine />} />
            <Route path="/subcategory" element={<SubCategory />} />
            <Route path="/subcategorylist" element={<SubCategoryList />} />

            <Route path="/event" element={<Event />} />
            <Route path="/cardlist" element={<CardList />} />
            <Route path="/addlocation" element={<AddLocation />} />
            <Route path="/userlist" element={<Userlist />} />
            <Route path="/banner" element={<Banner />} />
            <Route path="/blogs" element={<AddBlogs />} />

            <Route path="/membership" element={<MembershipPage />} />
            <Route path='/event-registration' element={<EventRegistration />} />
            <Route path='/addevent' element={<AddEvent />} />
            <Route path='/addlist' element={<EventList />} />
            <Route path='/addpolicy' element={<Addpolicy/>}/>
            <Route path='/faq' element={<AddFaq />} />



          </Route>
          <Route path="/" element={<Login />} />
        </Routes>
      </Sidebar>
    </BrowserRouter>
  );
}

export default App;
