import React, { useEffect, useState } from 'react'
import TitleComp from '../../component/TitleComp'
import { Form } from 'react-bootstrap'
import { deleteapi, getwithheader, postwithheader, putwithheader } from '../../Api/Apis';
import { toast } from 'react-toastify';
import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

function AddFaq() {
    let token = localStorage.getItem("paneltoken");
    const [editid, seteditid] = useState("")
    const [data, setdata] = useState([])
    const [question, setquestion] = useState("");
    const [answer, setanswer] = useState("");
    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission

        const requestData = {
            question: question,
            answer: answer,
        };
        if (!editid) {
            const res = await postwithheader('faqs', requestData, token);
            if (res.error == "0") {
                toast.success(res.message)
                handleget();
                setquestion(""); // Reset form fields
                setanswer("");

            } else {
                toast.error("Data not Submit")
            }
        } else {
            const res = await putwithheader(`faqs/${editid}`, requestData, token);
            if (res.error == "0") {
                toast.success(res.message)
                handleget();
                setquestion(""); // Reset form fields
                setanswer("");


            } else {
                toast.error("Data not Submit")
            }
        }




    };
    const handleget = async () => {
        const res = await getwithheader('faqs', token)
        console.log(res.data)
        setdata(res.data)


    }
    useEffect(() => {
        handleget();
    }, [])
    const handleedit = (e, item) => {
        e.preventDefault()

        setquestion(item.question)
        setanswer(item.answer)
        seteditid(item._id)
    }
    const handledelete = async (e, item) => {
        e.preventDefault()
        const res = await deleteapi(`faqs/${item._id}`, token)
        if (res.error == "0") {
            toast.success(res.message)
            handleget()
        } else {
            toast.error("Data not Submitted")
        }
    }



    const getRowId = (row) => row._id

    const columns = [
        {
            field: "question",
            headerName: "Question",
            flex: 1,

        },

        {
            field: "answer",
            headerName: "Answer",
            flex: 1,

        },

        {
            field: "action",
            headerName: "Action",
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        <div className='d-flex gap-2 mt-1'>
                            <button className='accepted btn bg-dark text-white' onClick={(e) => handleedit(e, params.row)}><i class="fa-solid fa-pen-to-square"></i> </button>
                            <button className='accepted btn bg-dark text-white' onClick={(e) => handledelete(e, params.row)} ><i class="fa-solid fa-trash"></i></button>
                        </div>
                    </>
                )
            }

        }
    ]
    return (
        <>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="text-center">
                                <TitleComp title="Add Faq" />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <Form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-4">
                                        <label htmlFor="">Question</label>
                                        <input type="text"
                                            className='form-control'
                                            value={question}
                                            onChange={(e) => setquestion(e.target.value)} />
                                    </div>
                                    <div className="col-md-4">
                                        <label htmlFor="">Answer</label>
                                        <input type="text" className='form-control'
                                            value={answer}
                                            onChange={(e) => setanswer(e.target.value)} />
                                    </div>
                                    <div className="col-md-4 mt-4">
                                        <button type="submit" className="btn bg-primary text-white">
                                            {editid ? "Update" : "Submit"}
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        </div>

                    </div>
                </div>
            </section>
            <section className='mt-3'>
                <div className="container">
                    <div className="row">
                        <div className="text-center">
                            <h3>FAQ List</h3>
                        </div>
                        <div className="col-md-12">
                            <Box>
                                <DataGrid
                                    rows={data}
                                    columns={columns}
                                    getRowId={getRowId}
                                />

                            </Box>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AddFaq
